<!--
 * @Author: gaojingran
 * @Date: 2021-04-15 10:46:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 11:40:14
 * @Description: 反馈修改
-->
<style lang="less" scoped>
.feedback-modal {
}
</style>

<template>
  <a-modal
    :width="580"
    :title="$t('orderInfo.btn_feedback_edit')"
    :cancelText="$t('cancel')"
    :okText="$t('submit')"
    :maskClosable="false"
    :visible="visible"
    @cancel="handleCancel"
    @ok="handleOk"
    :confirmLoading="confirmLoading"
  >
    <a-spin :spinning="loading">
      <div class="feedback-modal">
        <a-form-model ref="feedbackForm" :colon="true" :model="form" :rules="rules">
          <div>
            <NeoUploadButton
              v-model="attachmentFileList"
              sortType="unshift"
              :accept="acceptMap.orderSourceFile"
              :maxLength="5"
              :maxSize="10"
              :note="$t('validate.upload_number_size', { number: 5, size: 10, accept: acceptFormat })"
            />
          </div>
          <a-form-model-item prop="feedback" class="mb-2">
            <a-textarea
              v-model="form.feedback"
              style="width: 100%"
              :placeholder="$t('orderInfo.feedback_holder')"
              :auto-size="{ minRows: 4 }"
            />
          </a-form-model-item>
          <div class="mb-2">
            <NeoTips :label="$t('orderInfo.feedback_label')" :text="$t('orderInfo.feedback_tips')" />
          </div>
          <a-form-model-item
            prop="feedbackDueDate"
            labelAlign="left"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 18 }"
            :label="$t('orderInfo.new_delivery_time')"
          >
            <a-date-picker
              style="width: 220px"
              allowCler
              valueFormat="YYYY-MM-DD HH:mm:00"
              v-model="form.feedbackDueDate"
              :showTime="timePickerOption"
              :format="$t('dateFormat.a')"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import NeoUploadButton from '@/components/upload/NeoUploadButton'
import NeoTips from '@/components/common/NeoTips'

const { validate, acceptMap } = window.$g

export default {
  name: 'FeedbackModal',
  components: {
    NeoUploadButton,
    NeoTips,
  },
  props: {
    // 订单详情
    info: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      acceptMap,
      loading: false,
      confirmLoading: false,
      timePickerOption: {
        format: 'HH:mm',
      },
      attachmentFileList: [],
      form: {
        feedback: '',
        feedbackDueDate: undefined,
      },
      rules: {
        feedback: [validate.required, validate.size_200],
        feedbackDueDate: [validate.required, validate.earlier_time],
      },
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.attachmentFileList = []
        this.form = {
          feedback: '',
          feedbackDueDate: undefined,
        }
        this.$refs.feedbackForm.resetFields()
      }
    },
  },
  computed: {
    isInUploading() {
      const loading = this.attachmentFileList.find((v) => v.status === 'uploading')
      return this.$is.Defined(loading)
    },

    acceptFormat() {
      if (acceptMap.orderSourceFile) {
        return acceptMap.orderSourceFile
          .split(',')
          .map((v) => `*${v}`)
          .join(', ')
      }
      return '---'
    },
  },
  methods: {
    handleCancel() {
      this.$emit('update:visible', false)
    },
    handleOk() {
      if (this.isInUploading) {
        return
      }
      this.$refs.feedbackForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          this.confirmLoading = true
          try {
            await this.$http('orderProcessProduceFeedback', {
              id: this.info.id,
              attachmentFileList: this.attachmentFileList
                .filter((v) => v.status === 'done')
                .map((v) => ({
                  name: v.name,
                  url: v.url,
                })),
              ...this.form,
            })
            this.loading = false
            this.confirmLoading = false
            this.$message.success(this.$t('prompt.update_success'))
            // 刷新订单info
            this.$bus.$emit('_refresh_order_info_')
            this.handleCancel()
          } catch (err) {
            this.loading = false
            this.confirmLoading = false
            this.$httpNotify(err)
          }
        }
      })
    },
  },
}
</script>
